import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, onAuthStateChanged, firestore, collection, getDocs, doc, deleteDoc, deleteObject, ref, updateDoc, arrayUnion, arrayRemove, getDoc, storage } from './firebase-config';
import './MediaFeed.css';
import './App.css';
// import FABUploadMedia from './FABUploadMedia';
// import './FABUploadMedia.css';
import AudioPlayer from './AudioPlayer';

function MediaFeed() {
  const [userId, setUserId] = useState(null);
  const [media, setMedia] = useState([]);
  const [likes, setLikes] = useState({});  
  const [userSaves, setUserSaves] = useState({});
  const [userLikes, setUserLikes] = useState({});
  const [userProfiles, setUserProfiles] = useState({}); // State to store user profile pictures
  const [newComment, setNewComment] = useState({}); // To track comments for each media
  const [showComments, setShowComments] = useState({}); // Track visibility of comments for each media item
  const [mediaToDelete, setMediaToDelete] = useState(null); // Track which media item to delete
  const [prompts, setPrompts] = useState([]); // State for storing prompts
  const [combinedFeed, setCombinedFeed] = useState([]); // Combined feed of media and prompts
  const [promptLikes, setPromptLikes] = useState({});
  const [promptUserLikes, setPromptUserLikes] = useState({});
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchMedia = async () => {
      try {

        const mediaPath = `events/${eventCode}/media`;
        const mediaRef = collection(firestore, mediaPath);
        const querySnapshot = await getDocs(mediaRef);

        const mediaData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp ? data.timestamp.toDate() : null;
          return {
            id: doc.id,
            ...data,
            timestamp: timestamp, // Convert Firestore Timestamp to JavaScript Date
            comments: data.comments || [],
            userId: data.userId,
            savedBy: data.savedBy || [],
          };
        });

        const sortedMedia = mediaData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setMedia(sortedMedia);

        // Fetch likes
        const likesData = {};
        const userLikesData = {};
        const userSavesData = {};

        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          const mediaId = doc.id;
          likesData[mediaId] = data.likes ? data.likes.length : 0;
          userSavesData[mediaId] = data.savedBy && data.savedBy.includes(userId);
          if (data.likes && data.likes.includes(userId)) {
            userLikesData[mediaId] = true;
          }
        }
        setLikes(likesData);
        setUserLikes(userLikesData);
        setUserSaves(userSavesData);

      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    if (userId) {
      fetchMedia();
    }
  }, [userId, eventCode]);


  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const promptsPath = `events/${eventCode}/prompts`;
        const promptsRef = collection(firestore, promptsPath);
        const querySnapshot = await getDocs(promptsRef);
        const promptsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp ? data.timestamp.toDate() : null;
          return {
            id: doc.id,
            type: 'prompt',
            ...data,
            timestamp: timestamp,
            delivered: data.delivered || 'No',
          };
        });
        setPrompts(promptsData);
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    };
  
    fetchPrompts();
  }, [eventCode]);

  useEffect(() => {
    const fetchPromptsLikes = async () => {
      try {
        const promptsPath = `events/${eventCode}/prompts`;
        const promptsRef = collection(firestore, promptsPath);
        const querySnapshot = await getDocs(promptsRef);
  
        const promptLikesData = {};
        const promptUserLikesData = {};
  
        querySnapshot.docs.forEach((docSnap) => {
          const data = docSnap.data();
          const promptId = docSnap.id;
          // Ensure likes is defined (default to an empty array)
          promptLikesData[promptId] = data.likes ? data.likes.length : 0;
          if (data.likes && data.likes.includes(userId)) {
            promptUserLikesData[promptId] = true;
          }
        });
  
        // Update state variables dedicated for prompt likes
        setPromptLikes(promptLikesData);
        setPromptUserLikes(promptUserLikesData);
      } catch (error) {
        console.error('Error fetching prompts likes:', error);
      }
    };
  
    if (userId) {
      fetchPromptsLikes();
    }
  }, [userId, eventCode]);
  

  useEffect(() => {
    const combined = [...media, ...prompts]; // Combine media and prompts
    const sortedCombined = combined.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by date
    setCombinedFeed(sortedCombined);
  }, [media, prompts]); // Re-run whenever media or prompts change  

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const profiles = {};
        const uniqueUserIds = [...new Set(media.map(item => item.userId))]; // Get unique userIds

          // Fetch profiles only for unique userIds
          for (const userId of uniqueUserIds) {
            const userPath = `events/${eventCode}/profiles`;
            const profileDoc = await getDoc(doc(firestore, userPath, userId));
            if (profileDoc.exists()) {
              profiles[userId] = profileDoc.data().photoURL || ''; // Store the profile picture URL
            }
          }
        
        setUserProfiles(profiles); // Store fetched profile pictures in state
      } catch (error) {
        console.error('Error fetching user profiles:', error);
      }
    };

    if (media.length > 0) {
      fetchUserProfiles();
    }
  }, [eventCode, media]);

  const handleLike = async (itemId, itemType) => {
    try {
      // Choose the correct collection based on item type
      const collectionPath =
        itemType === 'prompt'
          ? `events/${eventCode}/prompts`
          : `events/${eventCode}/media`;
      const itemDoc = doc(firestore, collectionPath, itemId);
  
      // Check whether the current user already liked the item
      // Use separate state for prompts versus media if necessary
      const alreadyLiked =
        itemType === 'prompt'
          ? promptUserLikes[itemId]
          : userLikes[itemId];
  
      if (alreadyLiked) {
        await updateDoc(itemDoc, {
          likes: arrayRemove(userId),
        });
      } else {
        await updateDoc(itemDoc, {
          likes: arrayUnion(userId),
        });
      }
  
      const updatedDoc = await getDoc(itemDoc);
      const updatedData = updatedDoc.data();
      const updatedLikesCount = updatedData.likes ? updatedData.likes.length : 0;
  
      // Update the appropriate state based on the item type
      if (itemType === 'prompt') {
        setPromptLikes((prevLikes) => ({
          ...prevLikes,
          [itemId]: updatedLikesCount,
        }));
        setPromptUserLikes((prevUserLikes) => ({
          ...prevUserLikes,
          [itemId]: !promptUserLikes[itemId],
        }));
      } else {
        setLikes((prevLikes) => ({
          ...prevLikes,
          [itemId]: updatedLikesCount,
        }));
        setUserLikes((prevUserLikes) => ({
          ...prevUserLikes,
          [itemId]: !userLikes[itemId],
        }));
      }
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  const handleCommentChange = (mediaId, comment) => {
    setNewComment((prev) => ({
      ...prev,
      [mediaId]: comment, // Store new comment per media item
    }));
  };

  const handleCommentSubmit = async (itemId, itemType) => {
    // Check if there is a non-empty comment for the item
    if (newComment[itemId]?.trim()) {
      try {
        // Determine the correct collection based on item type.
        // For prompts, use the 'prompts' collection; otherwise, use 'media'.
        const collectionPath =
          itemType === 'prompt'
            ? `events/${eventCode}/prompts`
            : `events/${eventCode}/media`;
        const itemDoc = doc(firestore, collectionPath, itemId);
  
        // Update the document by adding the new comment to the 'comments' array.
        await updateDoc(itemDoc, {
          comments: arrayUnion({
            user: auth.currentUser.displayName, // The current user's display name
            text: newComment[itemId],
            userId: userId,
            id: Date.now()
          }),
        });
  
        // Retrieve the updated document so you can update your UI.
        const updatedDoc = await getDoc(itemDoc);
        const updatedData = updatedDoc.data();
  
        // Update the local state for the respective collection.
        if (itemType === 'prompt') {
          setPrompts((prevPrompts) =>
            prevPrompts.map((item) =>
              item.id === itemId
                ? { ...item, comments: updatedData.comments }
                : item
            )
          );
        } else {
          setMedia((prevMedia) =>
            prevMedia.map((item) =>
              item.id === itemId
                ? { ...item, comments: updatedData.comments }
                : item
            )
          );
        }
  
        // Clear the comment input for this item.
        setNewComment((prev) => ({
          ...prev,
          [itemId]: '',
        }));
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };  

  const toggleComments = (mediaId) => {
    setShowComments((prev) => ({
      ...prev,
      [mediaId]: !prev[mediaId], // Toggle the visibility of comments for the specific media
    }));
  };

  const handleDeleteComment = async (itemId, commentId, itemType) => {
    try {
      const collectionPath =
        itemType === 'prompt'
          ? `events/${eventCode}/prompts`
          : `events/${eventCode}/media`;
      const itemDocRef = doc(firestore, collectionPath, itemId);
      
      const itemSnapshot = await getDoc(itemDocRef);
      if (!itemSnapshot.exists()) {
        console.error(`Document with id ${itemId} does not exist.`);
        return;
      }
      
      const itemData = itemSnapshot.data();
      if (!itemData.comments || itemData.comments.length === 0) {
        console.error(`No comments found for item ${itemId}.`);
        return;
      }
      
      const commentToDelete = itemData.comments.find(
        (comment) => comment.id === commentId
      );
      
      await updateDoc(itemDocRef, {
        comments: arrayRemove(commentToDelete),
      });
      
      console.log(`Comment ${commentId} successfully deleted from item ${itemId}.`);
      
      if (itemType === 'prompt') {
        setPrompts((prevPrompts) =>
          prevPrompts.map((item) =>
            item.id === itemId
              ? { ...item, comments: item.comments.filter(c => c.id !== commentId) }
              : item
          )
        );
      } else {
        setMedia((prevMedia) =>
          prevMedia.map((item) =>
            item.id === itemId
              ? { ...item, comments: item.comments.filter(c => c.id !== commentId) }
              : item
          )
        );
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };
  

  const confirmDelete = (mediaId) => {
    setMediaToDelete(mediaId); // Set the media item that is to be deleted
  };

  const handleDelete = async () => {
    try {
      const mediaPath = `events/${eventCode}/media`;
      const mediaDocRef = doc(firestore, mediaPath, mediaToDelete);
  
      const mediaSnapshot = await getDoc(mediaDocRef);

      if (mediaSnapshot.exists()) {
        const mediaData = mediaSnapshot.data();
        const storagePath = mediaData.src;
        const storageRef = ref(storage, storagePath);
  
        await deleteObject(storageRef);
        await deleteDoc(mediaDocRef);
           
        setMedia((prevMedia) => prevMedia.filter((item) => item.id !== mediaToDelete));
        setMediaToDelete(null);
  
        console.log("Media successfully deleted from Firestore and Storage.");
      } else {
        console.error("Media document does not exist.");
      }
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };

  const handleCancel = () => {
    setMediaToDelete(null); // Close the confirmation form
  };

  const handleSave = async (itemId) => {
    const mediaPath = `events/${eventCode}/media`;
    const mediaDoc = doc(firestore, mediaPath, itemId);
    try {
      if (userSaves[itemId]) {
        await updateDoc(mediaDoc, {
          savedBy: arrayRemove(userId),
        });
      } else {
        await updateDoc(mediaDoc, {
          savedBy: arrayUnion(userId),
        });
      }

      setUserSaves((prevSaves) => ({
        ...prevSaves,
        [itemId]: !prevSaves[itemId],
      }));
    } catch (error) {
      console.error('Error saving media:', error);
    }
  };

  return (
    <div className="media-feed">
      {/* <FABUploadMedia /> */}
      {combinedFeed.length > 0 ? (
        <div className="media-list">
          {combinedFeed.map((item, itemIndex) => (
            <div className="media-item" key={itemIndex}>
              {item.type === 'media' ? (
                <>
                  <div className="media-content-wrapper">
                    <div className="media-header">
                      {userProfiles[item.userId] ? (
                        <img
                          src={userProfiles[item.userId]}
                          alt={item.displayname}
                          className="media-profile-picture"
                        />
                      ) : (
                        <span className="material-icons media-profile-picture">
                          account_circle
                        </span>
                      )}
                      <span className="username">{item.displayname}</span>
  
                      {item.userId === userId && (
                        <button
                          onClick={() => confirmDelete(item.id)}
                          className="delete-button"
                        >
                          <span className="material-icons">delete_outline</span>
                        </button>
                      )}
                    </div>
  
                    <img
                      src={item.src}
                      alt={item.name}
                      className="media-content"
                    />
  
                    {item.caption && (
                      <div className="media-caption">
                        <p>{item.caption}</p>
                      </div>
                    )}
                  </div>
  
                  <div className="media-reactions">
                    <div className="reaction-buttons">
                      <button
                        onClick={() => handleLike(item.id, item.type)}
                        className={`like-button ${userLikes[item.id] ? 'liked' : ''}`}
                      >
                        <span className="material-icons">
                          {userLikes[item.id] ? 'favorite' : 'favorite_outline'}
                        </span>
                        <span className="like-count">
                          {likes[item.id] || 0}
                        </span>
                      </button>
  
                      <button
                        onClick={() => toggleComments(item.id)}
                        className="comment-button"
                      >
                        <span className="material-icons">
                          {showComments[item.id] ? 'chat' : 'chat_bubble_outline'}
                        </span>
                        <span className="comment-count">
                          {item.comments.length || 0}
                        </span>
                      </button>
                    </div>
  
                    <button
                      onClick={() => handleSave(item.id)}
                      className="save-collection-button"
                    >
                      <span className="material-icons-outlined">
                        {userSaves[item.id] ? 'bookmark' : 'bookmark_border'}
                      </span>
                    </button>
                  </div>
  
                  {showComments[item.id] && (
                    <>
                      {item.comments.length > 0 && (
                        <div className="comments-section">
                          {item.comments.map((comment, commentIndex) => (
                            <div key={commentIndex} className="comment-item">
                              <span className="comment-user">
                                {comment.user}:
                              </span>
                              <span className="comment-text">
                                {comment.text}
                              </span>
                              {comment.userId === userId && (
                                <button
                                  onClick={() => handleDeleteComment(item.id, comment.id, item.type)}
                                  className="delete-comment-button"
                                >
                                <span className="material-icons">delete_outline</span>
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
  
                      <div className="comment-input-section">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          value={newComment[item.id] || ''}
                          onChange={(e) => handleCommentChange(item.id, e.target.value)}
                          className="comment-input"
                        />
                        <button
                          onClick={() => handleCommentSubmit(item.id, item.type)}
                          className="material-icons submit-button"
                        >
                          send
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : item.type === 'post' ? (
                <>
                  <div className="media-content-wrapper">
  
                    <img
                      src={item.src}
                      alt={item.name}
                      className="media-content"
                    />
  
                    {item.caption && (
                      <div className="media-caption">
                        <p>{item.caption}</p>
                      </div>
                    )}
                  </div>
      
                  {/* Like/Comment/Save Area (same as media) */}
                  <div className="media-reactions">
                    <div className="reaction-buttons">
                      <button
                        onClick={() => handleLike(item.id, item.type)}
                        className={`like-button ${userLikes[item.id] ? 'liked' : ''}`}
                      >
                        <span className="material-icons">
                          {userLikes[item.id] ? 'favorite' : 'favorite_outline'}
                        </span>
                        <span className="like-count">{likes[item.id] || 0}</span>
                      </button>
      
                      <button
                        onClick={() => toggleComments(item.id)}
                        className="comment-button"
                      >
                        <span className="material-icons">
                          {showComments[item.id] ? 'chat' : 'chat_bubble_outline'}
                        </span>
                        <span className="comment-count">
                          {item.comments.length || 0}
                        </span>
                      </button>
                    </div>
                  </div>
      
                  {/* Comments Section (same logic) */}
                  {showComments[item.id] && (
                    <>
                      {item.comments.length > 0 && (
                        <div className="comments-section">
                          {item.comments.map((comment, commentIndex) => (
                            <div key={commentIndex} className="comment-item">
                              <span className="comment-user">{comment.user}:</span>
                              <span className="comment-text">{comment.text}</span>
                              {comment.userId === userId && (
                                <button
                                  onClick={() => handleDeleteComment(item.id, comment.id, item.type)}
                                  className="delete-comment-button"
                                >
                                <span className="material-icons">delete_outline</span>
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
      
                      <div className="comment-input-section">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          value={newComment[item.id] || ''}
                          onChange={(e) => handleCommentChange(item.id, e.target.value)}
                          className="comment-input"
                        />
                        <button
                          onClick={() => handleCommentSubmit(item.id, item.type)}
                          className="material-icons submit-button"
                        >
                          send
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : item.type === 'voice-note' ? (
                <>
                  <div className="media-content-wrapper">
                    <div className="voicenote-media-header">
                      {userProfiles[item.userId] ? (
                        <img
                          src={userProfiles[item.userId]}
                          alt={item.displayname}
                          className="media-profile-picture"
                        />
                      ) : (
                        <span className="material-icons media-profile-picture">
                          account_circle
                        </span>
                      )}
                      <span className="username">{item.displayname}</span>
  
                      {item.userId === userId && (
                        <button
                          onClick={() => confirmDelete(item.id)}
                          className="delete-button"
                        >
                          <span className="material-icons">delete_outline</span>
                        </button>
                      )}
                    </div>
                    
                      <AudioPlayer src={item.src} />

                      {/* <audio
                        className="voice-note-player"
                        src={item.src}
                        controls
                      >
                        Your browser does not support the audio element.
                      </audio> */}
              
                    {/* Caption */}
                    {item.caption && (
                      <div className="media-caption">
                        <p>{item.caption}</p>
                      </div>
                    )}
                  </div>
      
                  {/* Like/Comment/Save Area (same as media) */}
                  <div className="media-reactions">
                    <div className="reaction-buttons">
                      <button
                        onClick={() => handleLike(item.id, item.type)}
                        className={`like-button ${userLikes[item.id] ? 'liked' : ''}`}
                      >
                        <span className="material-icons">
                          {userLikes[item.id] ? 'favorite' : 'favorite_outline'}
                        </span>
                        <span className="like-count">{likes[item.id] || 0}</span>
                      </button>
      
                      <button
                        onClick={() => toggleComments(item.id)}
                        className="comment-button"
                      >
                        <span className="material-icons">
                          {showComments[item.id] ? 'chat' : 'chat_bubble_outline'}
                        </span>
                        <span className="comment-count">
                          {item.comments.length || 0}
                        </span>
                      </button>
                    </div>
                  </div>
      
                  {/* Comments Section (same logic) */}
                  {showComments[item.id] && (
                    <>
                      {item.comments.length > 0 && (
                        <div className="comments-section">
                          {item.comments.map((comment, commentIndex) => (
                            <div key={commentIndex} className="comment-item">
                              <span className="comment-user">{comment.user}:</span>
                              <span className="comment-text">{comment.text}</span>
                              {comment.userId === userId && (
                                <button
                                  onClick={() => handleDeleteComment(item.id, comment.id, item.type)}
                                  className="delete-comment-button"
                                >
                                <span className="material-icons">delete_outline</span>
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
      
                      <div className="comment-input-section">
                        <input
                          type="text"
                          placeholder="Add a comment..."
                          value={newComment[item.id] || ''}
                          onChange={(e) => handleCommentChange(item.id, e.target.value)}
                          className="comment-input"
                        />
                        <button
                          onClick={() => handleCommentSubmit(item.id, item.type)}
                          className="material-icons submit-sms-button"
                        >
                          send
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                item.status === 'Sent' && (
                  <div className="chat-container">
                    <div className="chat-bubble">
                      <span className="prompt-description">
                        {item.promptDescription}
                      </span>
                      <span className="prompt-timestamp">
                        Delivered at{' '}
                        {item.timestamp
                          ? item.timestamp.toLocaleString()
                          : 'No timestamp available'}
                      </span>
                    </div>

                    <div className="media-reactions">
                      <div className="reaction-buttons">
                        <button
                          onClick={() => handleLike(item.id, item.type)}
                          className={`like-button ${promptUserLikes[item.id] ? 'liked' : ''}`}
                        >
                          <span className="material-icons">
                            {promptUserLikes[item.id] ? 'favorite' : 'favorite_outline'}
                          </span>
                          <span className="like-count">{promptLikes[item.id] || 0}</span>
                        </button>

                        <button
                          onClick={() => toggleComments(item.id)}
                          className="comment-button"
                        >
                          <span className="material-icons">
                            {showComments[item.id] ? 'chat' : 'chat_bubble_outline'}
                          </span>
                          <span className="comment-count">
                            {item.comments?.length || 0}
                          </span>
                        </button>
                      </div>
                    </div>

                    {showComments[item.id] && (
                      <>
                        {item.comments?.length > 0 && (
                          <div className="comments-section">
                            {item.comments?.map((comment, commentIndex) => (
                              <div key={commentIndex} className="comment-item">
                                <span className="comment-user">{comment.user}:</span>
                                <span className="comment-text">{comment.text}</span>
                                {comment.userId === userId && (
                                <button
                                  onClick={() => handleDeleteComment(item.id, comment.id, item.type)}
                                  className="delete-comment-button"
                                >
                                <span className="material-icons">delete_outline</span>
                                </button>
                              )}
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="comment-input-section">
                          <input
                            type="text"
                            placeholder="Add a comment..."
                            value={newComment[item.id] || ''}
                            onChange={(e) => handleCommentChange(item.id, e.target.value)}
                            className="comment-input"
                          />
                          <button
                            onClick={() => handleCommentSubmit(item.id, item.type)}
                            className="material-icons submit-button"
                          >
                            send
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No media found for this event.</p>
      )}
  
      {mediaToDelete && (
        <div className="delete-confirmation">
          <div className="confirmation-content">
            <p>Are you sure you want to delete this post?</p>
            <button onClick={handleDelete} className="confirm-delete">
              Yes, Delete
            </button>
            <button onClick={handleCancel} className="cancel-delete">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default MediaFeed;
