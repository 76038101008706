import React, { useState, useEffect, useRef } from "react";
import {
  doc,
  getDoc,
  setDoc,
  storage,
  firestore,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  addDoc,
  auth,
  serverTimestamp,
  onAuthStateChanged,
} from "./firebase-config";
import "./Camera.css";
import { useLocation } from "react-router-dom";

function Camera({ addMedia, onClose }) {
  const [file, setFile] = useState(null);
  const [caption, setCaption] = useState("");
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);
  const location = useLocation();
  const eventCode = location.pathname.split("/")[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else {
        setUserId(null);
        setDisplayName("");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const chosenFile = e.target.files?.[0];
    if (!chosenFile) return;

    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const originalDataURL = reader.result;
        // Apply color + noise overlay
        const finalDataURL = await applyVintageAndNoise(originalDataURL);
        setFile(finalDataURL);
      } catch (err) {
        console.error("Error applying filter:", err);
      }
    };
    reader.readAsDataURL(chosenFile);
  };

  async function applyVintageAndNoise(baseImageURL) {
    return new Promise((resolve, reject) => {
      const baseImg = new Image();

      const overlayImg = new Image();
      let loadedCount = 0;

      baseImg.onload = () => {
        loadedCount++;
        if (loadedCount === 2) finalize();
      };
      overlayImg.onload = () => {
        loadedCount++;
        if (loadedCount === 2) finalize();
      };

      baseImg.onerror = reject;
      overlayImg.onerror = reject;

      baseImg.src = baseImageURL;
      
      overlayImg.src = "/noise_filter.png";

      function finalize() {
        console.log('Finalize canvas');
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
      
        const MAX_WIDTH = 1080;
        const MAX_HEIGHT = 1080;
        let width = baseImg.width;
        let height = baseImg.height;
      
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }
      
        canvas.width = width;   
        canvas.height = height;
      
        ctx.drawImage(baseImg, 0, 0, width, height);

        applyCustomFilter(ctx, width, height);

        ctx.globalCompositeOperation = "overlay";
        ctx.globalAlpha = 0.2;
        ctx.drawImage(overlayImg, 0, 0, width, height);
      
        ctx.globalAlpha = 1;
        ctx.globalCompositeOperation = "source-over";
      
        const finalURL = canvas.toDataURL("image/jpeg", 0.8); 
        resolve(finalURL);
      }

      function applyCustomFilter(ctx, width, height) {
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;
        
        // Configuration – tweak these values as needed.
        const brightness = 1.1; // multiply RGB values (1 = no change)
        const contrast = 1.1;   // contrast factor
        const saturate = 0.8;   // saturation factor (1 = no change)
        const sepiaAmount = 0.3;

        // Sepia matrix coefficients
        // Standard sepia transformation:
        // R' = 0.393 R + 0.769 G + 0.189 B
        // G' = 0.349 R + 0.686 G + 0.168 B
        // B' = 0.272 R + 0.534 G + 0.131 B
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          
          // Fully convert to sepia using standard coefficients:
          const sepiaR = 0.393 * r + 0.769 * g + 0.189 * b;
          const sepiaG = 0.349 * r + 0.686 * g + 0.168 * b;
          const sepiaB = 0.272 * r + 0.534 * g + 0.131 * b;

          // Blend original and sepia colors based on sepiaAmount:
          let newR = r * (1 - sepiaAmount) + sepiaR * sepiaAmount;
          let newG = g * (1 - sepiaAmount) + sepiaG * sepiaAmount;
          let newB = b * (1 - sepiaAmount) + sepiaB * sepiaAmount;
             
          // Apply brightness and contrast (simple multiplication)
          newR *= brightness * contrast;
          newG *= brightness * contrast;
          newB *= brightness * contrast;

          // Apply saturation adjustment:
          // First, compute luminance (perceived brightness)
          const lum = 0.299 * newR + 0.587 * newG + 0.114 * newB;
          // Then interpolate between the luminance and the color by the saturation factor.
          newR = lum + (newR - lum) * saturate;
          newG = lum + (newG - lum) * saturate;
          newB = lum + (newB - lum) * saturate;
          
          // Clamp values to [0, 255]
          data[i] = Math.min(255, newR);
          data[i + 1] = Math.min(255, newG);
          data[i + 2] = Math.min(255, newB);
          // Leave alpha unchanged
        }
        
        ctx.putImageData(imageData, 0, 0);
      }
      
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !userId) {
      console.error("No file or not logged in");
      return;
    }

    setLoading(true);
    try {
      const resp = await fetch(file);
      const blob = await resp.blob();

      const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
      const storagePath = `events/${eventCode}/media/${uniqueId}`;
      const storageRef = ref(storage, storagePath);

      await uploadBytes(storageRef, blob);
      const fileUrl = await getDownloadURL(storageRef);

      const docPath = `events/${eventCode}/media`;
      const newMedia = {
        displayname: displayName,
        caption,
        src: fileUrl,
        timestamp: serverTimestamp(),
        userId,
        type: "media",
        uniqueId,
      };
      await addDoc(collection(firestore, docPath), newMedia);
      addMedia(newMedia);

      const profileDocRef = doc(firestore, `events/${eventCode}/profiles`, userId);
      const profileSnapshot = await getDoc(profileDocRef);
      if (!profileSnapshot.exists()) {
        await setDoc(profileDocRef, { photoURL: fileUrl });
      }

      onClose();
    } catch (err) {
      console.error("Error uploading file:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="camera-modal-overlay">
      <div className="camera-modal-content">
        <button className="media-modal-close" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>

        <div className="camera-preview-section">
          <div className="image-preview-container">
            {file ? (
              <img
                src={file}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            ) : (
              <p>Loading preview...</p>
            )}
          </div>

          <textarea
            className="caption-input"
            placeholder="Write a caption..."
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />

          <button
            type="submit"
            className="media-submit-btn"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Posting..." : "Post"}
          </button>
        </div>

        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          capture="camera"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default Camera;
