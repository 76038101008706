import React, { useState, useEffect, useRef } from "react";
import {
  doc,
  getDoc,
  setDoc,
  storage,
  firestore,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  addDoc,
  auth,
  serverTimestamp,
  onAuthStateChanged
} from "./firebase-config";
import "./UploadMediaTab.css";
import { useLocation } from "react-router-dom";

function UploadMediaTab({ addMedia, onClose }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [caption, setCaption] = useState("");
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const location = useLocation();
  const eventCode = location.pathname.split("/")[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else { 
        setUserId(null);
        setDisplayName("");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const MAX_WIDTH = 1080;
        const MAX_HEIGHT = 1080;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (!blob) return;
            const compressedFile = new File([blob], selectedFile.name, {
              type: "image/jpeg",
              lastModified: Date.now()
            });
            setFile(compressedFile);
            setPreviewUrl(URL.createObjectURL(blob));
          },
          "image/jpeg",
          0.8
        );
      };
    };
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (!file || !userId) {
      console.error("No file selected or user not logged in.");
      return;
    }

    setLoading(true);
    try {
      const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
      const storagePath = `events/${eventCode}/media/${uniqueId}`;
      const storageRef = ref(storage, storagePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      const newMedia = {
        prompt: 'Some Prompt',
        displayname: displayName,
        caption: caption,
        src: fileUrl,
        name: file.name,
        timestamp: serverTimestamp(),
        userId: userId,
        type: "media"
      };

      const docPath = `events/${eventCode}/media`;
      const docRef = await addDoc(collection(firestore, docPath), newMedia);
      console.log("Document written with ID:", docRef.id);

      addMedia && addMedia(newMedia);

      const profileDocRef = doc(firestore, `events/${eventCode}/profiles`, userId);
      const profileSnapshot = await getDoc(profileDocRef);
      if (!profileSnapshot.exists()) {
        await setDoc(profileDocRef, { photoURL: fileUrl });
      }

      setFile(null);
      setPreviewUrl("");
      setCaption("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      onClose && onClose();
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="uploadMediaContainer">
      <div className="uploadMediaHeader">
        <button className="cancelButton" onClick={onClose}>
          Cancel
        </button>
        {/* <h2 className="headerTitle">New Post</h2> */}
        <button
          className="postButton"
          onClick={handleSubmit}
          disabled={!file || loading}
        >
          {loading ? "Posting..." : "Post"}
        </button>
      </div>

      <div className="uploadMediaBody">
        {!previewUrl ? (
          <div className="fileInputArea">
            <label className="selectImageLabel">
              <span className="material-icons">add_a_photo</span>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                required
                style={{ display: "none" }}
              />
              <p>Select an image</p>
            </label>
          </div>
        ) : (
          <div className="imagePreviewContainer">
            <img src={previewUrl} alt="Preview" className="imagePreview" />

            <div className="captionContainer">
              <textarea
                className="captionInput"
                placeholder="Write a caption..."
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
          </div>
        )}


      </div>
    </div>
  );
}

export default UploadMediaTab;
