import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

export default function AudioPlayer({ src }) {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#fff',
        progressColor: 'black',
        cursorColor: 'white',
        barWidth: 1,
        responsive: true,
      });

      wavesurferRef.current.load(src);

      wavesurferRef.current.on('ready', () => {
        console.log('WaveSurfer is ready');
      });

      wavesurferRef.current.on('finish', () => {
        console.log('Playback finished');
        setIsPlaying(false);
      });

      wavesurferRef.current.on('play', () => setIsPlaying(true));
      wavesurferRef.current.on('pause', () => setIsPlaying(false));
    }
  }, [src]);

  const handlePlayPause = () => {
    if (!wavesurferRef.current) return;
    wavesurferRef.current.playPause();
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = 'audio-file.wav';
    link.click();
  };

  return (
    <div className="voice-note-container">
      <button onClick={handlePlayPause} className="audio-button">
        <span className="material-icons-outlined">
          {isPlaying ? 'pause_circle' : 'play_circle'}
        </span>
      </button>

      <div
        ref={waveformRef}
      />

      <button onClick={handleDownload}>
        <span className="material-icons-outlined">
          more_vert
        </span>
      </button>
    </div>
  );
}
